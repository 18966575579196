.container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

.error {
    color: red;
    text-align: center;
}

.productsList {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 20px;
}

.productCard {
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    background: #f9f9f9;
    text-align: center;
}

.addProductForm {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.addProductForm input,
.addProductForm button {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.addProductForm button {
    background-color: #4caf50;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.addProductForm button:hover {
    background-color: #45a049;
}

.mailingContainer {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin-left: 15%;
}

.inputContainer {
    opacity: 0.9;
    position: relative;
    top: 27px;
    width: 300px;
    padding: 10px;
}

.fileUpload {
    margin: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: -webkit-fill-available;
    height: 50px;
    border-radius: 10px;
    background: #111349;
    font-size: 16px;
    font-weight: bold;
    color: white;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    text-align: center;
    padding: 10px;
}

.fileUpload:hover {
    background: #eee;
    border-color: #666;
    color: #111349;
}

.fileInput {
    display: none;
    /* Скрываем стандартное поле загрузки */
}

.fileUpload span {
    pointer-events: none;
    /* Чтобы клик шел по всему label */
}

.priceLabel {
    display: flex;
    align-items: center;
    /* Выравниваем по центру */
    width: 100%;
    max-width: 300px;
    font-size: 16px;
    font-weight: bold;
    color: #444;
    margin-bottom: 10px;
}

.priceLabel span {
    color: white;
    margin-right: 10px;
    /* Отступ между текстом и input */
    white-space: nowrap;
    /* Чтобы текст не переносился */
}

.priceInput {
    flex-grow: 1;
    /* Растягивает input, чтобы он занимал доступное место */
    padding: 10px;
    font-size: 16px;
    border: 2px solid #ddd;
    border-radius: 8px;
    background: #f9f9f9;
    width: inherit;
    transition: all 0.3s ease-in-out;
}

.priceInput:focus {
    border-color: #007bff;
    background: #fff;
    outline: none;
}

textarea {
    display: block;
    background-color: #111349;
    width: 100%;
    height: 140px;
    padding: 10px;
    font-size: 16px;
    color: #ffffff;
    border: none;
    transition: all 0.1s ease;
}

textarea::placeholder {
    color: #ffffff;
    font-weight: 100;
}

.optionBox {
    display: flex;
    background-color: #111349;
    margin-top: 5px;
    width: 94%;
    padding: 10px;
    align-items: center;
    color: #ffffff;
    position: relative;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.saveBox {
    justify-content: center;
    display: flex;
    background-color: #111349;
    margin-top: 5px;
    width: 94%;
    padding: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
    align-items: center;
    color: #ffffff;
    position: relative;
    cursor: pointer;
}

.saveBox:hover {
    background-color: #c7c7c7;
    color: #111349;
}

.saveButton {
    cursor: pointer;
}

.paymentBox {
    justify-content: center;
    display: flex;
    background-color: #111349;
    margin-top: 5px;
    width: 94%;
    padding: 10px;
    align-items: center;
    color: #ffffff;
    position: relative;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.paymentBox:hover {
    background-color: #c7c7c7;
    color: #111349;
}

.payment {
    display: flex;
}

.payment_text {
    display: inline-table;
    margin-right: 10px;
}

.payment_input {
    border: 1px solid #ccc;
    font-size: 16px;
    width: 80px;
    padding: 5px;
    border-radius: 5px;
}

.dropdownContainer {
    position: relative;
    display: inline-block;
    width: 100%;
}

.dropdownButton {
    width: 100%;
    background: none;
    color: white;
    padding: 10px;
    font-size: 16px;
    cursor: pointer;
    border: none;
    text-align: left;
    background-color: #111349;
}

.dropdownButton:hover {
    background-color: #c7c7c7;
    color: #111349;
}

.dropdownButtonNumber {
    width: 70%;
    background: none;
    color: white;
    padding: 10px;
    font-size: 16px;
    cursor: pointer;
    border: none;
    text-align: left;
    background-color: #111349;
}

.dropdownButtonNumber:hover {
    background-color: #c7c7c7;
    color: #111349;
}

.dropdownButtonList {
    width: 20%;
    background: none;
    color: white;
    padding: 10px;
    cursor: pointer;
    background-color: #111349;
}

.dropdownButtonList:hover {
    background-color: #c7c7c7;
    color: #111349;
}

.dropdownList {
    display: block;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #c7c7c7;
    list-style: none;
    padding: 0;
    margin: 0;
    min-width: 150px;
    z-index: 1000;
    max-height: 200px;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #111349 #c7c7c7;
}

.dropdownList li {
    padding: 10px;
    color: #111349;
    cursor: pointer;
    transition: background-color 0.3s;
}

.dropdownList li:hover {
    background-color: #111349;
    color: #ffffff;
}

.serviceContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
}

.serviceBlocks {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 10px;
    justify-content: center;
}

.serviceBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.inputField {
    padding: 10px;
    border: 1px solid #ddd;
}

.counterBlock {
    justify-content: space-around;
    color: #ffffff;
    min-width: 200px;
    max-width: 200px;
    height: 100px;
    padding: 10px;
    text-align: center;
    background: #111349;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    opacity: 0.9;
}

.counterContainer {
    padding: 10px;
}

.costText {
    color: #ffffff;
}

.thunderIcon {
    height: 40px;
}

.savedTextContainers {
    color: white;
    width: 680px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    opacity: 0.9;
}

@media only screen and (max-width: 768px) {
    .savedText {
        width: 370px;
    }

    .mailingContainer {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        margin-left: none;
    }

    .serviceContainer {
        width: 300px;
    }

    .savedTextContainers {
        width: 400px;
    }
}