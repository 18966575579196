.containerMain {
    display: flex;
    justify-content: center;
    z-index: -10;
}

.containerContent {
    display: block;
    position: relative;
    background: linear-gradient(90deg, #111349, #060838, #000000);
    width: 1200px;
    padding: 20px;
    gap: 20px;
    flex-wrap: wrap;
    max-width: -webkit-fill-available;
    opacity: 0.9;
    margin-top: 100px;
    color: #ffffff;
    z-index: 1;
}

.block {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    border: 1px solid #ccc;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.counter {
    width: 180px;
    display: flex;
    align-items: center;
    gap: 5px;
    background: white;
    padding: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.counter button {
    width: 50px;
    height: 50px;
    font-size: 24px;
    font-weight: bold;
    border: none;
    background-color: #c7c7c7;
    color: #111349;
    cursor: pointer;
    transition: background-color 0.3s;
}

.counter button:hover {
    background-color: #111349;
    color: #c7c7c7;
}

.counter input {
    width: 80px;
    height: 50px;
    text-align: center;
    font-size: 20px;
    border: 1px solid #ccc;
    appearance: textfield;
    -moz-appearance: textfield;
}

.counter input::-webkit-inner-spin-button,
.counter input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.counter input:focus {
    outline: 2px solid #111349;
}

.exportContent {
    gap: 20px;
    position: relative;
    min-width: 50%;
}

.multiLineInput {
    width: 300px;
    height: 100px;
    padding: 10px;
    border: 2px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    overflow-y: auto;
    white-space: pre-wrap;
}

.multiLineInput:focus {
    outline: none;
    border-color: #007bff;
}

.uploadButton {
    display: block;
    flex-wrap: wrap;
    gap: 10px;
    height: 20px;
    width: inherit;
    background-color: #c7c7c7;
    color: #111349;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border: 1px solid #ccc;
    cursor: pointer;
}

.uploadButton:hover {
    background-color: #111349;
    color: #c7c7c7;
}

.listContainer {
    color: #000;
    width: 20%;
    max-height: 100px;
    overflow-y: auto;
    border: 1px solid #ddd;
    padding: 10px;
    background-color: #f9f9f9;
}

.device {
    width: 200px;
}

.switch {
    position: relative;
    display: inline-block;
    height: 34px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    width: 60px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ff0000;
    border-radius: 34px;
    transition: background-color 0.4s;
}

.slider::before {
    content: "";
    position: absolute;
    height: 26px;
    width: 26px;
    left: 3px;
    bottom: 4px;
    background-color: white;
    border-radius: 50%;
    transition: transform 0.4s;
}

input:checked+.slider {
    background-color: #00ff44;
}

input:checked+.slider::before {
    transform: translateX(26px);
}

.webcam {
    margin-top: 25px;
}

.payment_button {
    width: -webkit-fill-available;
    padding: 8px;
}

.payment_text {
    margin-top: 8px;
}

.payment_labels {
    position: -webkit-sticky;
}

.subExportContent {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.img_div {
    width: 200px;
}

.buyButton {
    background-color: #c7c7c7;
    color: #111349;
    cursor: pointer;
    padding: 10px;
    width: 200px;
    margin-top: 5px;
}

.title_antenna {
    display: flex;
}

.documentation_antenna {
    color: white;
}

/* Export content */
.text_input {
    display: block;
    position: relative;
    background: linear-gradient(90deg, #111349, #060838, #000000);
    width: -webkit-fill-available;
    height: 140px;
    padding: 10px;
    font-size: 16px;
    color: #ffffff;
    transition: all 0.1s ease;
}

.label_blocs {
    display: flex;
}

/* Second Block: Детекция */
.radarBackground {
    position: relative;
    width: 250px;
    /* Размер круга */
    height: 250px;
    /* Размер круга */
    background: radial-gradient(circle, #000000 50%, #111349 100%);
    border-radius: 50%;
    /* Делаем div кругом */
    display: flex;
    /* Центрируем img */
    justify-content: center;
    /* По горизонтали */
    align-items: center;
    /* По вертикали */
    overflow: hidden;
    /* Скрыть всё, что за кругом */
}

.radarContainer {
    width: 210px;
    height: 210px;
    object-fit: cover;
    border-radius: 50%;
}

/* Таблицы */

.gridContainer {
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.table {
    width: max-content;
    border-collapse: collapse;
}

.inner_table {
    border-collapse: collapse;
}

.table th,
.table td {
    padding: 10px;
    border: 1px solid #ccc;
    text-align: left;
}

.table th {
    background-color: #111349;
    color: #ffffff;
}

@media (max-width: 500px) {
    .table {
        font-size: 10px;
    }

    .gridContainer {
        grid-template-columns: 1fr 1fr;
        gap: 20px;
        justify-content: center;
        flex-wrap: wrap;
    }

    .adItem {
        flex: 1 1 100%;
    }


}