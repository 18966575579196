/* Стили для Header */
.navbar-header {
    z-index: 100;
    margin-top: 10px;
    width: 85%;
    opacity: 0.9;
    position: relative;
    /* ← добавь это */
}

.navbar-header.open {
    transform: translateX(0);
}

.navbar-mainUl {
    background: linear-gradient(90deg, #373857, #111349, #111349, #000000, #000000);
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    padding-left: 50px;
}

.navbar-bell {
    position: absolute;
    top: 15px;
    right: 230px;
    cursor: pointer;
    z-index: 100;
}

.navbar-bell img {
    height: 30px;
    padding: 5px;
    margin-right: 65px;
}

.navbar-dropdown {
    position: absolute;
    top: 36px;
    right: 0;
    background-color: white;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
    width: 340px;
    z-index: 999;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.dropdown-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 16px;
    background-color: #f7f7f8;
    font-weight: 600;
    border-bottom: 1px solid #e5e5e5;
}

.dropdown-header button {
    background: none;
    border: none;
    color: #007bff;
    font-size: 14px;
    cursor: pointer;
}

.dropdown-content {
    max-height: 320px;
    overflow-y: auto;
}

.notification-item {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 12px;
    padding: 14px 16px;
    border-bottom: 1px solid #f0f0f0;
}

.notification-icon {
    font-size: 20px;
    margin-top: 4px;
    flex-shrink: 0;
    margin-left: 12px;
}

.notification-text {
    flex-grow: 1;
}

.notification-text {
    max-width: 240px;
}

.notification-text p {
    margin: 4px 0 0;
    font-size: 14px;
    color: #555;
}

.notification-time {
    font-size: 12px;
    color: #999;
    white-space: nowrap;
    padding-left: 8px;
}

.notification-count {
    position: absolute;
    top: -6px;
    right: -6px;
    background-color: red;
    color: white;
    font-size: 12px;
    padding: 2px 6px;
    border-radius: 999px;
    font-weight: bold;
    line-height: 1;
    text-align: center;
    box-shadow: 0 0 0 2px white;
    margin-right: 70px;
}

.dropdown-footer {
    padding: 12px 16px;
    background-color: #f7f7f8;
    text-align: center;
    border-top: 1px solid #e5e5e5;
}

.dropdown-footer button {
    background: #111349;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 14px;
}

.dropdown-footer button:hover {
    background: #2c2f77;
}

.navbar-bell:hover {
    color: #000;
}

.navbar-logo {
    width: 220px;
    height: auto;
    z-index: 1000;
}

.navbar-logoContainer {
    margin-top: 10px;
    margin-left: 5px;
}

.navbar-contain {
    display: flex;
    position: relative;
}

nav ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    padding-left: 50px;
}

nav ul li {
    position: relative;
}

nav ul li a {
    display: block;
    color: rgb(255, 255, 255);
    text-decoration: none;
    padding: 16px 20px;
    transition: background-color 0.3s;
}

nav ul li a:hover {
    background-color: #c7c7c7;
    color: #111349;
}

nav ul li ul {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #c7c7c7;
    color: #111349;
    padding: 0;
    margin: 0;
    min-width: 150px;
    z-index: 1000;
}

nav ul li ul li a {
    padding: 10px 15px;
    font-size: 14px;
}

nav ul li ul a {
    color: #111349;
}

nav ul li:hover ul {
    display: block;
}

nav ul li ul li a:hover {
    background-color: #111349;
    color: #ffffff;
}

nav ul li a.active {
    background-color: #c7c7c7;
    color: #111349;
}

/* Responsive Styles */
@media only screen and (max-width: 768px) {

    /* Стили для бургера */
    .burger {
        display: block;
        position: fixed;
        top: 1rem;
        right: 1rem;
        width: 2rem;
        height: 2rem;
        cursor: pointer;
        z-index: 101;
    }

    .burger span {
        display: block;
        width: 100%;
        height: 2px;
        background: #c7c7c7;
        margin: 15px 0;
        transition: all 0.3s ease;
        transform: rotate(-60deg);
    }

    .burger span:nth-child(1) {
        position: absolute;
        margin-top: 15px;
        margin-left: -10px;
    }

    .burger span:nth-child(2) {
        position: absolute;
        margin-left: -5px;
    }

    .burger span:nth-child(3) {
        position: absolute;
        /* margin-top: 15px; */
    }

    .burger.open span:nth-child(1) {
        transform: translateY(8px) rotate(50deg);
        background-color: #c7c7c7;
        transition: all .7s;
        position: absolute;
        top: -10px;
        left: 10px;
        margin-left: 5;
    }

    .burger.open span:nth-child(2) {
        opacity: 0;
    }

    .burger.open span:nth-child(3) {
        transform: translateY(-8px) rotate(-50deg);
        background-color: #c7c7c7;
        transition: all .1s;
    }

    .navbar-header {
        flex-direction: column;
        align-items: flex-start;
        position: fixed;
        top: 0;
        right: 0;
        height: 100%;
        width: 100%;
        margin-top: 60px;
        background: #fff;
        transform: translateX(100%);
        transition: transform 0.3s ease;
        z-index: 100;
        padding: 1rem;
    }

    .navbar-header.open {
        transform: translateX(0);
    }

    .navbar-logoContainer {
        margin-bottom: 1rem;
    }

    .navbar-bell {
        right: 1px;
    }
}