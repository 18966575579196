.window {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    height: 100%;
    padding: 20px;
    box-sizing: border-box;
    overflow-y: auto;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 8px;
}

.header h3 {
    margin: 0;
    font-size: 18px;
    color: #1976d2;
}

.statusBadge {
    background-color: #e0f7fa;
    color: #00796b;
    padding: 4px 10px;
    border-radius: 12px;
    font-size: 13px;
    font-weight: 500;
}

.messages {
    flex-grow: 1;
    overflow-y: auto;
    padding: 8px 4px 12px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    background-color: #f7f9fb;
    border-radius: 8px;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.05);
}

.inputArea {
    display: flex;
    gap: 10px;
    margin-top: 16px;
    border-top: 1px solid #e0e0e0;
    padding-top: 12px;
}

.inputArea input {
    flex: 1;
    padding: 10px 12px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 6px;
    height: 40px;
    box-sizing: border-box;
}

.sendButton {
    width: 40px;
    height: 40px;
    font-size: 18px;
    background-color: #1976d2;
    color: white;
    border: none;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.2s ease;
    box-sizing: border-box;
}

.sendButton:hover {
    background-color: #1565c0;
}

.inputArea button {
    padding: 10px 16px;
    background-color: #1976d2;
    color: white;
    font-weight: bold;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.inputArea button:hover {
    background-color: #1565c0;
}

.attachButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    font-size: 18px;
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 6px;
    background: #fff;
    transition: background-color 0.2s ease;
    box-sizing: border-box;
}

.attachButton:hover {
    background-color: #eee;
}

.inputAreaWrapper {
    width: 100%;
    box-sizing: border-box;
    padding: 8px;
    margin-top: auto;
    background-color: #fff;
    border-top: 1px solid #ddd;
}

.inputArea {
    display: flex;
    gap: 8px;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
}

.emojiPicker {
    display: flex;
    gap: 6px;
    padding: 6px;
    background: #f9f9f9;
    border-radius: 6px;
    border: 1px solid #ddd;
    flex-wrap: wrap;
}

.emoji {
    font-size: 20px;
    cursor: pointer;
    transition: transform 0.1s ease;
}

.emoji:hover {
    transform: scale(1.2);
}

.empty {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #888;
    font-size: 15px;
}

.iconButton {
    width: 40px;
    height: 40px;
    font-size: 20px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 6px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.2s ease;
}

.iconButton:hover {
    background-color: #eee;
}

.statusCard {
    background: #f4f4f4;
    border-left: 4px solid #1976d2;
    padding: 10px 14px;
    border-radius: 8px;
    font-size: 13px;
    color: #444;
    line-height: 1.5;
    margin: 8px 0;
}