.chatWrapper {
    position: relative;
    display: inline-block;
    margin-top: 12px;
    width: 100%;
}

.chatButton {
    padding: 8px 16px;
    background-color: #111349;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    transition: background 0.2s ease;
}

.chatButton:hover {
    background-color: #0d0f3b;
}

.chatLabel {
    bottom: -18px;
    right: 8px;
    font-size: 12px;
    color: #555;
    font-style: italic;
    opacity: 0.85;
    white-space: nowrap;
}