.cardsContainer {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    max-width: 500px;
    margin: auto;
    margin-top: 20px;
    color: #111349;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
}

.cardList {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 20px;
}

.cardItem {
    display: flex;
    flex-direction: column;
    background: #1e1e2f;
    border-radius: 12px;
    padding: 20px;
    color: white;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    position: relative;
}

.cardVisual {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 16px;
    border-radius: 12px;
    position: relative;
}

.selectedCard {
    border: 2px solid #00c853;
    box-shadow: 0 0 10px rgba(0, 200, 83, 0.5);
}

.selectedCardDisplay {
    margin-bottom: 20px;
    padding: 16px;
    border: 2px dashed #00c853;
    border-radius: 12px;
    background: #f4fff6;
    color: #111349;
}

.cardChip {
    width: 40px;
    height: 28px;
    background: linear-gradient(45deg, #d2b48c, #c0a16b);
    border-radius: 4px;
    margin-bottom: 10px;
}

.cardNumber {
    font-size: 18px;
    letter-spacing: 2px;
    font-family: "Courier New", monospace;
}

.cardMeta {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
}

.cardExpiry {
    color: #ccc;
}

.cardBrand {
    font-weight: bold;
    color: #ffcc00;
    /* VISA желтая, можно заменить */
    font-size: 16px;
}

.cardActions button {
    margin-left: 8px;
}

.cardActions {
    display: flex;
    gap: 8px;
    margin-top: 10px;
}

.selectButton,
.deleteButton {
    padding: 6px 12px;
    border: none;
    border-radius: 6px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.selectButton {
    background-color: #111349;
    color: white;
}

.selectButton:hover {
    background-color: #181c60;
}

.deleteButton {
    background-color: #f44336;
    color: white;
}

.deleteButton:hover {
    background-color: #d32f2f;
}

.expiryWrapper {
    display: flex;
    gap: 10px;
}

.expiryWrapper select {
    padding: 8px;
    font-size: 16px;
    border-radius: 6px;
    border: 1px solid #ccc;
    width: 100%;
}

.addButton {
    margin-top: 20px;
    background: #111349;
    color: white;
    padding: 10px 16px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
}

.addButton:hover {
    background: #181c60;
}

.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal {
    background: white;
    padding: 24px;
    border-radius: 10px;
    width: 300px;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.modal input {
    padding: 8px;
    font-size: 16px;
}

.modalButtons {
    display: flex;
    justify-content: space-between;
    gap: 12px;
    margin-top: 10px;
}

.saveButton,
.cancelButton {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 10px 14px;
    font-size: 15px;
    font-weight: 600;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
    transition: all 0.2s ease;
}

.saveButton {
    background-color: #111349;
    color: white;
}

.saveButton:hover {
    background-color: #181c60;
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.cancelButton {
    background-color: #f0f0f0;
    color: #111349;
}

.cancelButton:hover {
    background-color: #e0e0e0;
    transform: translateY(-1px);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}