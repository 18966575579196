.chatPage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  font-family: 'Segoe UI', sans-serif;
  padding: 24px;
  box-sizing: border-box;
}

.chatPage::before {
  content: "";
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.35);
  z-index: 0;
}

.chatContainer {
  display: flex;
  width: 100%;
  max-width: 1200px;
  height: 100%;
  max-height: 820px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 16px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  z-index: 1;
  backdrop-filter: blur(8px);
  position: relative;
}

.sidebarToggle {
  display: none;
  position: absolute;
  top: 16px;
  left: 16px;
  background: none;
  border: none;
  font-size: 28px;
  z-index: 2;
  cursor: pointer;
}

.sidebarWrapper {
  width: 300px;
}

.backButton {
  background: none;
  border: none;
  font-size: 18px;
  margin: 12px;
  cursor: pointer;
  color: #007bff;
  align-self: flex-start;
  z-index: 3;
  padding: 8px 0;
}

.chatWindowWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.window {
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  padding-bottom: 8px;
}

.inputAreaWrapper {
  padding: 8px;
  background: white;
  border-top: 1px solid #eee;
}

.inputArea {
  display: flex;
  gap: 8px;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
}

.inputArea input {
  flex: 1;
  padding: 8px;
}

@media (max-width: 768px) {
  .chatContainer {
    flex-direction: column;
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
  }

  .sidebarToggle {
    display: block;
  }

  .sidebarWrapper {
    width: 100%;
    max-height: 50vh;
    overflow-y: auto;
    border-bottom: 1px solid #ccc;
  }
}