.select,
.input,
.button {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    outline: none;
}

.containerMain {
    display: flex;
    justify-content: center;
    z-index: -10;
    align-items: flex-start;
    gap: 24px;
}


.containerContent {
    display: block;
    position: relative;
    background: #111349;
    padding: 20px;
    gap: 20px;
    flex-wrap: wrap;
    max-width: 100%;
    opacity: 0.9;
    margin-top: 100px;
    color: #ffffff;
    z-index: 1;
}

.adsSidebar {
    position: absolute;
    top: 20px;
    margin-top: 100px;
    right: 0;
    width: 220px;
    background-color: #ffffff;
    color: #111349;
    padding: 16px;
    border-radius: 10px;
    box-shadow: -2px 0 8px rgba(0, 0, 0, 0.05);
    z-index: 10;
}

.adsBox {
    background-color: #f3f3f3;
    padding: 12px;
    margin-top: 12px;
    border-radius: 8px;
    text-align: center;
    font-weight: 500;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
}

.leftPanel,
.rightPanel {
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex: 1;
    min-width: 280px;
}

.balanceBox {
    background: #e0e0e0;
    padding: 15px;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    color: #111349;
}

.formGroup {
    display: flex;
    flex-direction: column;
    gap: 5px;
}


.button {
    background-color: #c7c7c7;
    color: #111349;
}

.button:hover {
    background-color: #111349;
    color: white;
}

.transactions {
    border: 1px solid #ccc;
    overflow-y: auto;
    max-height: auto;
}

.transactions table {
    width: 100%;
    border-collapse: collapse;
}

.transactions th,
.transactions td {
    border: 1px solid #ccc;
    padding: 10px;
    text-align: left;
}

.transactions th {
    background: #f0f0f0;
    color: #111349;
}

.transactions td {
    color: #ffffff;
}

@media only screen and (max-width: 768px) {
    .container {
        flex-direction: column;
        gap: 15px;
    }

    .balanceBox {
        font-size: 20px;
        padding: 10px;
    }

    select,
    input,
    button {
        font-size: 14px;
        padding: 8px;
    }

    .transactions th,
    .transactions td {
        font-size: 12px;
        padding: 8px;
    }
}

@media (max-width: 450px) {

    .transactions th,
    .transactions td {
        font-size: 8px;
        padding: 3px;
    }

    button {
        font-size: 10px;
        padding: 6px;
    }
}