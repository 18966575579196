/* src/components/AdsSidebar/AdsSidebar.module.css */
.sidebar {
    position: absolute;
    top: 10%;
    right: 0;
    width: 250px;
    height: 73%;
    background-color: #f9f9f9;
    border-left: 1px solid #ddd;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.05);
    z-index: 10;
}

.adBanner {
    width: 100%;
    border-radius: 6px;
    object-fit: cover;
    margin-top: 12px;
}

@media only screen and (max-width: 768px) {
    .sidebar {
        position: relative;
        margin-top: 20px;
        margin-left: 15px;
        width: 85%;
    }
}

.bottomAdsContainer {
    margin-top: 40px;
    background-color: #ffffff;
    border-radius: 12px;
    padding: 24px;
    color: #111349;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.adsHeading {
    margin-bottom: 16px;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
}

.bottomAds {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: space-between;
}

.adItem {
    flex: 1 1 calc(33.333% - 12px);
    background-color: #f1f1f1;
    padding: 20px;
    text-align: center;
    border-radius: 8px;
    font-weight: 500;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
    min-width: 200px;
}